<template>
    <div class="wallet" @click="goWalletPage" :class="skin">
        <div
            v-for="transaction in data"
            :key="transaction.mode"
            class="transaction-item"
        >
            <b-media no-body>
            <b-media-aside>
                <b-avatar
                rounded
                size="36"
                :variant="transaction.avatarVariant"
                >
                <feather-icon
                    size="18"
                    :icon="transaction.avatar"
                />
                </b-avatar>
            </b-media-aside>
            </b-media>
            <div
                class="font-weight-bolder mr-2"
                :class="transaction.deduction ? 'text-danger':'text-success'"
                >
                {{ payment }} TL
            </div>
        </div>
    </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'

import useAppConfig from '@core/app-config/useAppConfig'


export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [
            {
              mode: 'Paypal',
              types: 'Add Money',
              avatar: 'GiftIcon',
              payment: '',
              avatarVariant: 'light-success',
              deduction: false,
            },
        ],
    },
  },
  methods: {
      goWalletPage() {
        if(this.$route.name !== "wallet-page") this.$router.push({ name: 'wallet-page' })
      }
  },
  computed: {
    payment() {
      return store.getters['wallet-store/wallet'].availableBalance
    }
  },
  mounted() {
    store.dispatch('wallet-store/fetchWallet')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    return {
      skin,
      skinClasses
    }
  }
}
</script>
<style lang="scss" scoped>
.transaction-item{
    display: flex;
    align-items: center;
    justify-content: center;
}
.wallet {
  border-radius: 6px;
  cursor: pointer;
}
.dark {
  background-color: #161e31;
}
.light {
  background-color: #f3f3f3;
}
</style>